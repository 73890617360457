body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "AkrobatSemiBold";
  src: local("AkrobatSemiBold"),
   url("./fonts/Akrobat/Akrobat-SemiBold.otf") format("truetype");
 }

 @font-face {
  font-family: "AkrobatBold";
  src: local("AkrobatBold"),
   url("./fonts/Akrobat/Akrobat-Bold.otf") format("truetype");
 }

 @font-face {
  font-family: "Impact";
  src: local("Impact"),
   url("./fonts/Impact.ttf") format("truetype");
 }