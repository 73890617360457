.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 40px;
}

.header-logo {
  width: 106px;
  height: 70px;
}

.header-menu {
  font-family: 'AkrobatSemiBold';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;
  cursor: pointer;
  width: 294px;
  height: 22px;
}

.header-icon {
  display: none;
}

.header-menu-item {
  width: 91px;
  height: 22px;
  font-weight: 500;
  white-space: nowrap;
}

.header-menu-item:hover {
  color: #F36E21;
}

.header-button {
  font-family: 'AkrobatSemiBold';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  border: none;
  background: #F36E21;
  color: #FFFFFF;
  border-radius: 40px;
  width: 122px;
  height: 49px;
  cursor: pointer;
}

.header-button a {
  color: white;
  text-decoration: none;
}

.header-button:hover {
  opacity: 0.8;
}


.hero-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px;
  width: 50%;
  margin: 0px auto 0 22%;
}

.hero-container img {
  height: 500px;
}

.switch {
  margin: 0 auto;
  width: 30%;
}

.switch-container {
  position: relative;
  display: inline-block;
  border: none;
  width: 540px;
  height: 80px;
}

.switch-container input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #F5F5F5;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 70px;
  width: 70px;
  left: 6px;
  bottom: 5px;
  background-color: #F36E21;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider.active {
  background-color: black;
}

.slider.active div {
  display: none;
}



.slider.active:before {
  -webkit-transform: translateX(458px);
  -ms-transform: translateX(458px);
  transform: translateX(458px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 50px;
}

.slider.round:before {
  border-radius: 50%;
}

.slider.round div {
  font-family: 'AkrobatSemiBold' !important;
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 45px;
  position: absolute;
  color: #212121;
  top: 16px;
  left: 160px;
}


.about {
  margin: 200px auto;
  width: 90%;
}

.about-title {
  font-family: 'Impact';
  text-align: center;
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  padding: 30px 0 100px;
  color: #212121;
}

.about-container {
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 24px;
}

.about-container-box {
  box-sizing: border-box;
  width: 30%;
  height: 450px;
  background: #FFFFFF;
  border: 2px solid #F36E21;
  border-radius: 40px;
  flex: none;
  order: 0;
  flex-grow: 0
}

.about-container-box:first-child img {
  width: 80%;
  margin: -40% 0 0 10%;
}

.about-container-box:nth-child(3) img {
  width: 100%;
  margin-top: -50%;
}

.about-container-box div {
  position: absolute;
  bottom: 0;
  width: 50%;
  font-family: 'AkrobatSemiBold';
  font-style: normal;
  text-align: left;
  font-size: 22px;
  padding: 0 20px 30px;
  color: #212121;
}

.about-container-box:nth-child(2) {
  border: none;
  background: black;
}

.about-container-box:nth-child(2) div {
  color: white;
}

.about-container-box:nth-child(2) img {
  width: 100%;
  margin-top: -10%;
}

.offer {
  margin: 200px auto 0;
  width: 90%;
}

.offer-title {
  font-family: 'Impact';
  text-align: center;
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  padding: 30px 0;
  color: #212121;
}

.offer-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 24px;
}

.offer-container-box {
  position: relative;
  box-sizing: border-box;
  width: 22%;
  height: 400px;
  background: #FFFFFF;
  border: 2px solid black;
  border-radius: 40px;
  flex: none;
  order: 0;
  flex-grow: 0
}

.offer-container-box-title {
  padding: 30px 30px;
  min-width: 170px;
  height: 38px;
  font-family: 'AkrobatSemiBold';
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
}

.offer-container-box-text {
  font-family: 'AkrobatSemiBold';
  font-style: normal;
  font-size: 20px;
  margin-left: 30px;
  width: 90%;
}

.offer-container-box-details {
  display: flex;
  position: absolute;
  left: 30px;
  bottom: 20px;
}

.offer-container-box-details-left>div {
  font-family: 'AkrobatSemiBold';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 140%;
  width: 100px;
  padding-bottom: 10px;
}

.offer-container-box-details-right {
  width: 100%;
  height: 58px;
  margin: 20px 0 0 10px;
  font-family: 'Impact';
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  color: #F36E21;
}


.offer-additional-services {
  position: relative;
  margin: 200px auto 0;
  width: 95%;
}

.offer-additional-services-title {
  width: 90%;
  margin: 0 auto;
  height: 34px;
  padding-bottom: 40px;
  text-align: center;
  font-family: 'AkrobatBold';
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
}

.offer-additional-services-container {
  font-family: 'AkrobatSemiBold';
  font-size: 22px;
  display: flex;
  margin: 0 auto;
  box-sizing: border-box;
  width: 100%;
  height: 180px;
  left: 0px;
  top: 0px;
  background: #FFFFFF;
  border: 2px solid #212121;
  border-radius: 40px;
}

.offer-additional-services-items {
  width: 100%;
  padding: 50px;
}

.offer-additional-services-items-mobile {
  display: none;
}

.offer-additional-services-item div {
  padding-bottom: 10px;
}

.offer-additional-services-item>div>span {
  text-transform: lowercase;
  color: #F36E21;

}

.offer-additional-services-flame {
  display: none;
}

.offer-additional-services-flame img {
  width: 130px;
}

.offer-button-container {
  margin: 0 auto;
  width: 15%;
  padding: 50px 0;
}

.offer-button {
  font-family: 'AkrobatSemiBold';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  border: none;
  background: #F36E21;
  color: #FFFFFF;
  border-radius: 40px;
  width: 200px;
  height: 49px;
  cursor: pointer;
}

.offer-button:hover {
  opacity: 0.8;
}

.main {
  overflow: hidden;
}

.offer-fluffy-right img {
  margin-top: -220px;
  margin-left: 1050px;
  width: 700px;

}

.offer-facilities {
  font-family: 'AkrobatSemiBold';
  font-size: 24px;
  line-height: 140%;
  position: relative;
  top: -500px;
  left: 18%;
  width: 683px;
  height: 463px;
  border: 2px solid #F36E21;
  background: white;
  border-radius: 40px;
}

.offer-facilities:before {
  content: ' ';
  position: absolute;
  width: 0;
  height: 0;
  left: 40px;
  bottom: -50px;
  border: 25px solid;
  border-color: #F36E21 transparent transparent #F36E21;
}

.offer-facilities:after {
  content: ' ';
  position: absolute;
  left: 43px;
  bottom: -44px;
  border: 24px solid;
  border-color: white transparent transparent white;
}

.offer-facilities-item {
  display: flex;
  padding: 30px;
}

.offer-facilities-item img {
  width: 80px;
  height: 80px;
}

.offer-facilities-item div {
  padding-left: 40px;
}

.offer-fluffy-left img {
  margin-top: -900px;
  margin-left: -200px;
  width: 700px;
}

.toast {
  position: absolute;
  right: 0;
  display: flex;
  /* Initial position off-screen */
  transform: translateY(-50%);
  height: 220px;
  background: #212121;
  color: white;
  border-radius: 40px;
  opacity: 0;
  transition: all 1s ease;
  /* Transition effect */
}

.toast::after {
  content: ' ';
  position: absolute;
  width: 0;
  height: 0;
  right: 40px;
  bottom: -50px;
  border: 25px solid;
  border-color: #212121 #212121 white white;
}

.toast-text {
  margin: 34px;
  font-family: 'AkrobatSemiBold';
  font-style: normal;
  font-size: 20px;
}

.toast-text div {
  padding-bottom: 20px;
}

.toast-text div:nth-child(2) {
  font-family: 'AkrobatBold';
}

.toast img {
  margin-top: 10px;
  height: 170px;
  margin-left: -50px;
}

/* Apply styles when the scroll height is reached */
.toast.scroll-reached {
  right: 20%;
  /* Position from the right side */
  opacity: 1;
}


.service {
  margin: 0 auto;
  width: 90%;
}

.service-title {
  font-family: 'Impact';
  text-align: center;
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  padding: 30px 0;
  color: #212121;
}

.service-slider {
  display: flex;
  margin: 0 auto;
  box-sizing: border-box;
  width: 100%;
  height: 670px;
  left: 0px;
  top: 0px;
  background: #FFFFFF;
  border: 2px solid #212121;
  border-radius: 40px;

}



.service-slider-info {
  width: 40%;
  opacity: 0;
}

.service-slider-info.active {
  transition: opacity 0.5s ease-out;
  opacity: 1;
}

.service-slider-text {
  margin: 30% auto 0;
  width: 80%;
  font-size: 20px;
  color: #212121;
}

.service-slider-text-title {
  font-family: 'Impact';
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  padding-bottom: 10px;
  color: #212121;
  padding-bottom: 30px
}

.service-slider-text-decription {
  font-family: 'AkrobatSemiBold';
  font-style: normal;
  font-weight: 400;
  padding-bottom: 20px;
}

.service-slider-image-container {
  width: 60%;
  opacity: 0;
}

.service-slider-image-container-mobile {
  display: none;
}

.service-slider-image-container.active {
  transition: opacity 0.5s ease-out;
  opacity: 1;
}


.service-slider-image-wrapper {
  width: 90%;
  margin: 0 auto;
}

.service-slider-image-container img {
  width: 800px;
  margin-top: 20px;
}


.service-slider-text button {
  font-family: 'AkrobatSemiBold';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  border: none;
  background: #F36E21;
  color: #FFFFFF;
  border-radius: 40px;
  margin: 20px 0 0 -5px;
  width: 200px;
  height: 49px;
  cursor: pointer;
}

.service-slider-text button:hover {
  opacity: 0.8;
}

.service-slider-button-container {
  display: flex;
  margin-top: 20px;
  justify-content: center;
}

.service-slider-button-container button {
  margin: 0 10px;
  font-family: 'AkrobatSemiBold';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  border: none;
  background: #F36E21;
  color: #FFFFFF;
  border-radius: 40px;
  height: 64px;
  cursor: pointer;
}

.service-slider-button-container button:hover {
  opacity: 0.8;
}

.service-slider-button-container svg {
  margin-top: 5px
}

.footer {
  font-family: 'AkrobatSemiBold';
  font-size: 24px;
  margin: 200px auto 20px;
  width: 90%;
  height: 540px;
  display: flex;
  background: #212121;
  border-radius: 48px;
  color: white;
}

.footer>div {
  width: 33%;
}

.footer-left-logo {
  width: 350px;
  margin: 70px;
}

.footer-left-icons-container {
  display: flex;
  margin-left: 70px;
}

.footer-left-icons-container span {
  background-color: #F36E21;
  padding: 10px 16px;
  margin: 0 10px;
  border-radius: 50px;
}

.footer-left-icons-container span:hover {
  opacity: 0.8;
}

.footer-left-icons-container #facebook-icon {
  position: relative;
  top: 2px;
  left: 2px;
}

.footer-left-icons-container #instagram-icon {
  position: relative;
  top: 4px;
}

.footer-left-icons-container #tiktok-icon {
  position: relative;
  top: 1px;
  left: 1px;
}


.footer-middle-title {
  font-family: 'Impact';
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  margin-top: 70px;
}

.footer-middle-contact {
  margin-top: 20px;
}

.footer-middle-contact>div {
  padding: 10px 0;
}

.footer-middle-contact a {
  color: white;
  text-decoration: none;
}

.footer-middle-location {
  margin-top: 30px;
}

.footer-middle-location div {
  padding: 10px 0;
}

.footer-middle-location div a {
  color: white;
}

.footer-middle-location div:first-child {
  text-decoration: underline;
  cursor: pointer;
}

.footer-right-heart {
  margin-top: 70px;
  width: 350px;
}

.footer-right-policies {
  display: flex;
  margin-top: 50px;
}

.footer-right-policies-mobile {
  display: none;
}

.footer-right-policies div {
  padding: 0 10px;
  text-decoration: underline;
}

.footer-right-policies div a {
  color: white;
}


@media (min-width:1025px) and (max-width:1620px) {
  .offer-container-box-details-right {
    font-size: 30px;
  }

  .service-slider-image-container img {
    width: 90%;
  }
}

/* iPad Pro resolution */
@media (max-width: 1024px) {

  .switch {
    width: 50%;
  }

  .about-container-box div {
    width: 45%;
  }

  .offer-container-box {
    width: 46%;
  }

  .offer-additional-services-container {
    flex-wrap: wrap;
    height: auto;
  }

  .offer-additional-services-items {
    width: 32%;
    padding: 28px 0 20px 30px;
  }

  .offer-container {
    flex-wrap: wrap;
  }

  .offer-additional-services-flame {
    display: block;
    position: absolute;
    right: 25px;
    bottom: 20px;
  }

  .offer-button-container {
    width: 30%;
  }

  .offer-fluffy-right img {
    margin-left: 500px;
  }

  .offer-facilities {
    font-size: 18px;
    left: 6%;
    width: 520px;
    height: 370px;
  }

  .offer-facilities-item img {
    width: 60px;
    height: 60px;
  }

  .offer-fluffy-left img {
    margin-top: -900px;
    margin-left: -100px;
    width: 400px;
  }

  .service-slider {
    height: 450px;
  }
  .service-slider-button-container {
    display: none;
  }

  .service-slider-info {
    width: 100%;
  }

  .service-slider-text {
    margin: 5% auto 0;
  }

  .service-slider-image-container img {
    width: 350px;
    margin: 160px 0 0 -300px;
  }

  .service-slider-image-container {
    width: 10%;
  }

  .footer-right {
    display: none;
  }

  .footer>div {
    width: 50%;
  }

  .footer-left-logo {
    width: 250px;
    margin: 60px;
  }

  .footer-middle-title {
    margin-top: 30px;
  }

  .footer-right-policies-mobile {
    display: flex;
    margin: 40px 0 0 -10px;
  }

  .footer-right-policies-mobile div {
    padding: 0 10px;
    text-decoration: underline;
  }

  .footer-right-policies-mobile div a {
    color: white;
  }

  .footer-left-icons-container {
    margin: 180px 0 0 70px;
  }
}

/* iPhone 14 Pro resolution */
@media (max-width: 460px) {
  .header-logo {
    width: 50px;
    height: auto;
  }

  .header-menu {
    display: none;
  }

  .hero-wrapper {
    height: 300px;
  }

  .hero-container img {
    width: 420px;
    height: auto;
  }

  .header-icon {
    display: block;
    cursor: pointer;
  }

  .header-button {
    width: 80px;
    height: 25px;
    font-size: 12px;
    line-height: 15px;
  }

  .header-menu-mobile {
    position: absolute;
    background-color: #212121;
    border-radius: 20px;
    width: 110px;
    height: 190px;
    top: 10px;
    right: 10px;
    font-family: 'AkrobatSemiBold';
    font-size: 16px;
    color: white;
    padding: 20px;
  }

  .header-menu-mobile div {
    padding-bottom: 30px;
  }

  .header-menu-mobile span {
    font-family: 'AkrobatBold';
    position: absolute;
    top: 6px;
    right: 12px;
  }

  .switch {
    width: 85%;
  }

  .switch-container {
    width: 350px;
    height: 60px;
  }

  .slider:before {
    height: 50px;
    width: 50px;
  }

  .slider.round div {
    font-size: 20px;
    line-height: 25px;
    top: 18px;
    left: 130px;
  }

  input:checked+.slider:before {
    -webkit-transform: translateX(290px);
    -ms-transform: translateX(290px);
    transform: translateX(290px);
  }

  .about {
    margin: 100px auto;
  }

  .about-container {
    flex-wrap: wrap;
  }

  .about-container-box {
    width: 90%;
    margin-bottom: 40px;
    height: 400px;
  }

  .about-title {
    padding: 30px 0 50px;
  }

  .about-container-box:first-child img {
    width: 60%;
    margin: -10% 0 0 20%;
  }

  .about-container-box div {
    width: 90%;
    bottom: auto;
    font-size: 20px;
    padding: 20px 5px 0;
  }

  .about-container-box:nth-child(3) img {
    width: 80%;
    margin: -20% 0 0 10%;
  }

  .about-container-box:nth-child(3) div {
    padding: 6px 5px 0;
  }

  .offer-container-box-details-right {
    margin: 20px 0 0 0;
  }

  .offer {
    margin: 0 auto;
  }

  .offer-additional-services-title {
    margin: 0 auto 60px;
  }

  .offer-additional-services-container {
    height: 160px;
  }

  .offer-container-box {
    width: 90%;
  }

  .offer-additional-services-flame {
    display: none;
  }

  .offer-additional-services-container {
    font-size: 13px;
  }

  .offer-additional-services-items {
    display: none;
  }

  .offer-additional-services-items-mobile {
    display: block;
    position: relative;
    top: 30px;
    left: 40px;
  }

  .offer-additional-services-item div {
    padding-left: 10px;
  }

  .offer-button-container {
    width: 50%;
    position: relative;
    z-index: 2;
  }

  .offer-fluffy-right img {
    margin-left: 150px;
  }

  .offer-facilities-item {
    flex-wrap: wrap;
  }

  .offer-facilities {
    width: 340px;
    height: 740px;
  }

  .offer-facilities-item div {
    padding-left: 0;
    text-align: center;
  }

  .offer-facilities-item img {
    margin: 0 auto 10px;
  }

  .service-slider {
    display: block;
    height: 480px;
  }

  .service-slider-image-container {
    display: none;
  }

  .service-slider-image-container-mobile {
    display: block;
    opacity: 0;
  }

  .service-slider-image-container-mobile.active {
    transition: opacity 0.5s ease-out;
    opacity: 1;
  }

  .service-slider-image-container-mobile img {
    width: 300px;
    margin-left: 40px;
  }

  .service-slider-text-title {
    padding-bottom: 10px;
    font-size: 20px;
    text-align: center;
  }

  .service-slider-text {
    margin: 0 auto;
    text-align: center;
    font-size: 16px;
  }

  .service-slider-text button {
    margin: 0 auto;
    width: 300px;
    height: 46px;
    font-size: 12px;
    top: 6540px;
    margin-left: -150px;
    position: absolute;
  }

  .footer {
    display: block;
    height: 590px;
  }

  .footer-left div {
    margin: 0 auto;
  }

  .footer-left-icons-container {
    margin: 0;
    width: 100%;
  }

  .footer-left-icons-container div {
    margin: 0 auto;
  }

  .footer-middle-title {
    font-size: 20px;
  }

  .footer>div {
    width: auto;
  }

  .footer-middle {
    text-align: center;
  }

  .footer-middle-contact {
    font-size: 12px;
    margin-top: 0;
  }

  .footer-middle-location {
    font-size: 12px;
  }

  .footer-middle-contact>div {
    padding: 0;
  }

  .footer-middle-location div {
    padding: 0;
  }

  .footer-right-policies-mobile {
    display: block;
  }

  .footer-right-policies-mobile div {
    padding-bottom: 10px;
  }
  .toast {
    height: 190px;
    width: 360px;
  }
  .toast.scroll-reached {
    right: 5%;
    opacity: 1;
  }
  .toast-text {
    font-size: 14px;
    margin: 20px;
  }
  .toast img {
    margin-top: 0;
    height: 150px;
  }
}